import { useEffect, useRef } from "react";
import Aos from "aos";
import ImgLogo from "./assets/images/img-logo-app.png";
import IconTwitter from "./assets/images/icon-twitter.png";
import IconTelegram from "./assets/images/icon-telegram.png";
import ImgTokenomics from "./assets/images/img-tokenomics.png";
import IconTwitterWhite from "./assets/images/icon-twitter-white.png";
import IconTelegramWhite from "./assets/images/icon-telegram-white.png";
import "./App.scss";
import "aos/dist/aos.css";

function App() {
  const snowContainerRef = useRef(null);

  useEffect(() => {
    const snowContainer = snowContainerRef.current;

    const particlesPerThousandPixels = 0.1;
    const fallSpeed = 1.25;
    const pauseWhenNotActive = true;
    const maxSnowflakes = 200;
    const snowflakes = [];

    let snowflakeInterval;
    let isTabActive = true;

    function resetSnowflake(snowflake) {
      const size = Math.random() * 5 + 1;
      const viewportWidth = window.innerWidth - size; // Adjust for snowflake size
      const viewportHeight = window.innerHeight;

      snowflake.style.width = `${size}px`;
      snowflake.style.height = `${size}px`;
      snowflake.style.left = `${Math.random() * viewportWidth}px`; // Constrain within viewport width
      snowflake.style.top = `-${size}px`;

      const animationDuration = (Math.random() * 3 + 2) / fallSpeed;
      snowflake.style.animationDuration = `${animationDuration}s`;
      snowflake.style.animationTimingFunction = "linear";
      snowflake.style.animationName =
        Math.random() < 0.5 ? "fall" : "diagonal-fall";

      setTimeout(() => {
        if (parseInt(snowflake.style.top, 10) < viewportHeight) {
          resetSnowflake(snowflake);
        } else {
          snowflake.remove(); // Remove when it goes off the bottom edge
        }
      }, animationDuration * 1000);
    }

    function createSnowflake() {
      if (snowflakes.length < maxSnowflakes) {
        const snowflake = document.createElement("div");
        snowflake.classList.add("snowflake");
        snowflakes.push(snowflake);
        snowContainer.appendChild(snowflake);
        resetSnowflake(snowflake);
      }
    }

    function generateSnowflakes() {
      const numberOfParticles =
        Math.ceil((window.innerWidth * window.innerHeight) / 1000) *
        particlesPerThousandPixels;
      const interval = 5000 / numberOfParticles;

      clearInterval(snowflakeInterval);
      snowflakeInterval = setInterval(() => {
        if (isTabActive && snowflakes.length < maxSnowflakes) {
          requestAnimationFrame(createSnowflake);
        }
      }, interval);
    }

    function handleVisibilityChange() {
      if (!pauseWhenNotActive) return;

      isTabActive = !document.hidden;
      if (isTabActive) {
        generateSnowflakes();
      } else {
        clearInterval(snowflakeInterval);
      }
    }

    generateSnowflakes();

    window.addEventListener("resize", () => {
      clearInterval(snowflakeInterval);
      setTimeout(generateSnowflakes, 1000);
    });

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      // Cleanup event listeners or perform any other cleanup if needed
      clearInterval(snowflakeInterval);
      window.removeEventListener("resize", generateSnowflakes);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <div className="snow-container" ref={snowContainerRef}></div>
      <header className="page-header">
        <div className="container">
          <div className="logo-app">
            <img src={ImgLogo} alt="" />
          </div>
          <nav className="nav-menu">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#tokenomics">TOKENOMICS</a>
              </li>
              <li>
                <a
                  href="https://dexscreener.com/solana/4tzJRaFXyBojrPrLJCM5APKuWy4QwHGUMtpaGyVREC8c"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CHARt
                </a>
              </li>
            </ul>
          </nav>
          <div className="socials">
            <a
              href="https://twitter.com/BonkXmas_Sol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconTwitter} alt="" />
            </a>
            <a
              href="https://t.me/BonkXmas_Sol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconTelegram} alt="" />
            </a>
          </div>
        </div>
      </header>

      <main className="page-main">
        <section className="section-intro"></section>
        <section className="section-about" id="about">
          <div data-aos="fade-up" className="container wrapper">
            <h2 className="title">About</h2>
            <h3 className="sub-title">
              Bonk Xmas <span> on Solana</span>
            </h3>
            <p className="des">
              Discover the enchanting tale of Bonk Xmas, where a fantastical
              flying sleigh, led by a spirited brigade of reindeers, embarks on
              a crypto-fueled Christmas adventure. In this festive odyssey, Bonk
              Xmas doesn't just deliver seasonal cheer – it brings you a
              sleigh-load of surprises and tokens. Join us as we redefine
              holiday magic, blending the joy of giving with the innovation of
              blockchain, making every moment with Bonk Xmas a celebration that
              transcends tradition and embraces the spirit of the future.
            </p>
            <p className="contact">
              Contract:
              <br /> FD6dCccQY8si5Bp7fWMsbSeT97xXt4WuCnrMjWxh4T7U
            </p>
            <div className="btn-actions">
              <a
                href="https://dexscreener.com/solana/4tzJRaFXyBojrPrLJCM5APKuWy4QwHGUMtpaGyVREC8c"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-buy-now"
              >
                Buy now
              </a>
              <a
                href="https://dexscreener.com/solana/4tzJRaFXyBojrPrLJCM5APKuWy4QwHGUMtpaGyVREC8c"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-chart"
              >
                Chart
              </a>
            </div>
          </div>
        </section>
        <section className="section-how-to-buy">
          <div data-aos="fade-up" className="container wrapper">
            <h2 className="title">how to buy</h2>
            <div className="list-content">
              <div className="content">
                <h3>How to buy $BONKXMAS token?</h3>
                <p>
                  Bonk Xmas token will be listing on Solana network. All you
                  need to prepare is SOL, and wait for our launch. Then, use our
                  Contract to buy tokens on Raydium
                </p>
              </div>
              <div className="content">
                <h3>Does Bonk Xmas have any community?</h3>
                <p>
                  Bonk Xmas has two main social channels: Telegram and Twitter.
                  We have a strong and engagement community with a friendly
                  admin team. Be a part of it by joining groups on our Website
                  now.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-tokenomics" id="tokenomics">
          <div data-aos="fade-up" className="container wrapper">
            <h2 className="title">TOKENOMICS</h2>
            <div className="list-tokenomics">
              <div className="item">
                <img src={ImgTokenomics} alt="" />{" "}
                <div className="content">
                  <h3>100m</h3>
                  <p>Total Supply</p>
                </div>
              </div>
              <div className="item">
                <img src={ImgTokenomics} alt="" />{" "}
                <div className="content">
                  <h3>0%</h3>
                  <p>Tax</p>
                </div>
              </div>
              <div className="item">
                <img src={ImgTokenomics} alt="" />{" "}
                <div className="content">
                  <h3>BURNT</h3>
                  <p>Liquidity</p>
                </div>
              </div>
              <div className="item">
                <img src={ImgTokenomics} alt="" />
                <div className="content">
                  <h3>REVOKED</h3>
                  <p>Mint</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="page-footer">
        <div className="container wrapper">
          <div className="logo-footer">
            <img src={ImgLogo} alt="" />
          </div>
          <p>© Bonk Xmas - All Rights Reserved</p>
          <div className="socials">
            <a
              href="https://t.me/BonkXmas_Sol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconTelegramWhite} alt="" />
            </a>
            <a
              href="https://twitter.com/BonkXmas_Sol"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={IconTwitterWhite} alt="" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
